import "./SearchBar.scss";
import * as React from "react";
import {useEffect, useState, useMemo} from "react";
import ClearIcon from "../../assets/images/clear-icon.svg";
import {
    IonItem, IonInput, IonIcon, IonButton
} from "@ionic/react";
import SearchIcon from "../../assets/images/search-icon.svg";
import SearchIconGray from "../../assets/images/search-icon-gray.svg";
import LodashDebounce from "lodash.debounce";
import {useTranslation} from "react-i18next";

interface Props {
    onNewSearchQuery: (query: string) => void;
    onClearSearchQuery: () => void;
    isSearchLoading: boolean;
    searchPlaceholder?: Maybe<string>;
    initialSearch?: Maybe<string>
}

export function sanitizeSearchInput(inputString: string | null) {
    return inputString ? inputString.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null;
}

export const SearchBar: React.FC<Props> = ({onNewSearchQuery, isSearchLoading, onClearSearchQuery, searchPlaceholder, initialSearch}) => {

    const [searchInput, setSearchInput] = useState<string | null>(null);
    const { t } = useTranslation();

    const debouncedCallback = useMemo(() => {
        return LodashDebounce(onNewSearchQuery, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //This is a guard to avoid making multiple calls to the API before one is already in process
        if(!isSearchLoading){
            if(searchInput) {
                debouncedCallback(searchInput);
            } else {
                onClearSearchQuery();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);
    

    function handleClearSearch () {
        setSearchInput(null);
    }

    useEffect(() => {
        setSearchInput(initialSearch ?? null);
    },[initialSearch]);


    return(
        <IonItem className="search-bar-component">
            <IonInput
                className="header-6 search-input"
                value={searchInput}
                placeholder={searchPlaceholder ?? t('search') ?? "Search"}
                onIonChange={e => setSearchInput(sanitizeSearchInput((e.detail.value!)))}>
            </IonInput>
            {searchInput !== null && <IonButton className={"clear-button"} onClick={handleClearSearch} fill={"clear"}><IonIcon icon={ClearIcon} /></IonButton>}
            <IonIcon className={"search-icon"} slot="end" icon={searchInput === null ? SearchIcon : SearchIconGray} />
        </IonItem>
    );
};


