import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import "./ContentArticlePage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {ApiArticle, ApiResourcesContentArticle, MediaType} from "../../utils/ApiTypes";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {useParams} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { ResourcesBackButton } from '../../components/Resources/ResourcesBackButton';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import useQueryChecker from '../../components/CustomHooks/useQueryChecker';

type ResourcesArticleRouteParams = {
    articleId: string;
};

export const ContentArticlePage: React.FC = () => {

    const {isMobileWidth, handleGeneralError} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const [article, setArticle] = useState<ApiResourcesContentArticle | null>(null);
    const [articleMedia, setArticleMedia] = useState<ApiArticle | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const params = useParams<ResourcesArticleRouteParams>();
    const {isMatch, isCheckLoading} = useQueryChecker("isMedia", "1");

    useEffect(() => {
        if(isComponentVisible && !isCheckLoading) {
            if(isMatch){
                getMediaContent(params.articleId);
            } else {
                getContentArticle(params.articleId);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, isCheckLoading]);

    function getContentArticle(articleId: string) {
        setIsLoading(true);
        console.log("Getting article content");
        JourneyApiClient.getResourcesContentArticle(articleId).then(article => {
            setArticle(article);
            setIsLoading(false);
        }).catch( (error: any) => {
            handleGeneralError("Unable to fetch Resources Article by ArticleId", error);
            setIsLoading(false);
        })
    }

    function getMediaContent(mediaId: string) {
        setIsLoading(true);
        JourneyApiClient.getMedia(mediaId).then((media) => {
            if(media && (media.type === MediaType.Article)){
                setArticleMedia(media as ApiArticle);
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false);
            handleGeneralError("Unable to fetch Resources Article by ArticleId", error);
        });
    }

    return(
        <IonPage className="content-article-page">

            <ResponsiveNavigationMenu title={"Resources"} />

            <IonContent className="article-content">
                {isLoading || (!article && !articleMedia) ? <PleaseWait/> :
                <div className="article-container">
                     {!isMobileWidth && <ResourcesBackButton />}
                    <div className="article-title header-5">
                        {article?.title ?? articleMedia?.title}
                    </div>
                    <div className="article-body body-medium" dangerouslySetInnerHTML={{__html: article?.body ?? articleMedia?.body ?? ""}}>
                    </div>
                </div>}
            </IonContent>
        </IonPage>
    )
}
