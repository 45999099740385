import "./DailyJourney.scss";
import * as React from 'react';
import { useEffect } from "react";
import { ApiDailyJourney } from "../../utils/ApiTypes";
import { DailyVideo } from "../DailyVideo/DailyVideo";
import { DAILY_JOURNEY_PATH } from '../../utils/utils';
import { ApplicationContext } from '../../misc/ApplicationContext';
import { useState } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { LoginModalComponent } from "../LoginModalComponent/LoginModalComponent";
import AnalyticsService from "../../misc/AnalyticsService";
import ClientConfig from "../../utils/ClientConfig";
import { useLocation } from "react-router-dom";
import ExpandMore from "../../assets/images/expand-more.svg";
import { MuxAudioPlayer } from "../MuxAudioPlayer/MuxAudioPlayer";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { TalkToSomeone } from "../TalkToSomeone/TalkToSomeone";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';
import { Feature } from "../Feature/Feature";

interface DailyJourneyProps {
    dailyJourney: ApiDailyJourney;
    showTruncatedArticle?: boolean;
    isComponentVisible: boolean;
    onAudioEnd?: () => void;
    playAudio?: boolean;
    showTalkToSomeone?: boolean
}

export const DailyJourney: React.FC<DailyJourneyProps> = (props: DailyJourneyProps) => {

    const { isMobileWidth, currentUser } = React.useContext(ApplicationContext);
    const formattedDate = new Date(props.dailyJourney.publicationDate);
    const [isReadMorePressed, setIsReadMorePressed] = useState<boolean>(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [shareComponentUrl, setShareComponentUrl] = useState<string>("");
    const location = useLocation();
    const dateString = formattedDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
    const djRef = React.useRef<any>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.playAudio) {
            djRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [props.playAudio]);

    useEffect(() => {
        if (currentUser) {
            const urlBase = currentUser.company.customWebUrlRoot ? currentUser.company.customWebUrlRoot : ClientConfig.newFrontendRoot;
            setShareComponentUrl(`${urlBase}${DAILY_JOURNEY_PATH}/${props.dailyJourney.id}?shared=${currentUser.id}`);
        } else {
            setShareComponentUrl(`${ClientConfig.newFrontendRoot}${DAILY_JOURNEY_PATH}/${props.dailyJourney.id}`);
        }
    }, [currentUser, props.dailyJourney]);

    async function handleButtonPressed() {
        await trackReadMoreButtonClicked();
        if (!currentUser) {
            setIsLoginModalOpen(true);
            return;
        }
        setIsReadMorePressed(true);
    }

    async function trackReadMoreButtonClicked() {
        await AnalyticsService.trackUserAction("daily_journey_read_more_button_clicked",
            location.pathname, { daily_journey_id: props.dailyJourney.id });
    }

    return (
        <div className='daily-journey-component' ref={djRef}>
            <LoginModalComponent
                isComponentVisible={props.isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />
            <div className="two-panel-container">
                <div className="left-panel">
                    <div className='title-date-container'>
                        <div className={`title ${isMobileWidth ? "header-6" : "header-4"}`}>
                            {props.dailyJourney.theme}
                        </div>
                        <div className='date caption'>
                            {`${t("Daily Journey")}: ${dateString}`}
                        </div>

                    </div>


                    
                    <Feature feature="daily-audio">
                        {props.dailyJourney.muxAudio &&
                            <div className={`mux-audio-player ${!props.showTruncatedArticle || isReadMorePressed ? "less-padding" : ""}
                            `}>
                                <MuxAudioPlayer
                                    videoId={props.dailyJourney.muxAudio.id}
                                    duration={props.dailyJourney.muxAudio.duration}
                                    isLoading={false}
                                    isComponentVisible={props.isComponentVisible}
                                    muxPlaybackId={props.dailyJourney.muxAudio.muxPlaybackId}
                                    muxVideoId={props.dailyJourney.muxAudio.muxVideoId}
                                    title={props.dailyJourney.muxAudio.title}
                                    currentUser={currentUser}
                                    thumbnail={props.dailyJourney.muxAudio.thumbnailUrl}
                                    autoplay={props.playAudio ?? false}
                                    onAudioEnd={() => props.onAudioEnd?.()} />
                            </div>
                        }
                    </Feature>

                    {(!props.showTruncatedArticle || isReadMorePressed) ?
                        <div className={`inner ${isMobileWidth ? "body-small" : "body-medium"}`} dangerouslySetInnerHTML={{ __html: props.dailyJourney.body ?? "" }} />
                        :
                        <div>
                            <div className='inner body-small' dangerouslySetInnerHTML={{ __html: props.dailyJourney.truncatedBody ?? "" }} />
                            <div className="more-text-button-container">
                                <IonButton fill={"clear"} onClick={handleButtonPressed} className="more-text-button button-medium-variant">
                                    {t("Read More")} <IonIcon className={"expand-more-icon"} src={ExpandMore} />
                                </IonButton>
                            </div>
                        </div>
                    }

                    {
                        (props.dailyJourney.videoDescriptionNewApp && props.dailyJourney.videoCoverImageUrl) &&
                        <div className='inner'>
                            <h1>{t("Got a few more?")}</h1>

                            <div className='video-info'>

                                <div className='column'>
                                    <img src={props.dailyJourney.videoCoverImageUrl} alt='Daily Journey VideoStyle Cover' />
                                </div>

                                <div className='column info' dangerouslySetInnerHTML={{ __html: props.dailyJourney.videoDescriptionNewApp ?? "" }} />
                            </div>

                        </div>

                    }
                </div>
                <div className="right-panel">
                    {props.dailyJourney.muxVideo && <Feature feature="daily-video">
                        <DailyVideo
                            className='daily-journey-video'
                            playVideoOnMute={!props.showTruncatedArticle}
                            muxVideo={props.dailyJourney.muxVideo}
                            currentUser={currentUser}
                            isDailyJourneyVideo={true}
                            shareDailyJourneyUrl={shareComponentUrl}
                        />
                    </Feature>}
                    {
                        props.showTalkToSomeone && currentUser && 
                        <Feature feature="has-eap">
                            <TalkToSomeone
                                className={"daily-journey-talk-to-someone"}
                                user={currentUser}
                                isMobileWidth={isMobileWidth}
                                showDescription={false}
                                showDescriptionInPopover={true}
                                subtitle={t("All support is free and confidential.")}
                                isCondensed={true}
                                isComponentVisible={props.isComponentVisible} />
                        </Feature>
                    }
                </div>
            </div>

            <div className='divider' />
        </div>
    );
}
