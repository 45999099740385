import "./AssessmentModal.scss";
import * as React from "react";
import {IonModal} from "@ionic/react";
import {ApiMonthlyCheckinAnswerV101} from "../../utils/ApiTypes";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {AssessmentWelcomeStep} from "./AssessmentWelcomeStep";
import {useHistory} from "react-router-dom";

interface assessmentModalProps {
    monthlyAssessmentIsAllowed: boolean;
    hasSeenMonthlyAssessment: boolean;
}

export const AssessmentModal: React.FC<assessmentModalProps> = (props: assessmentModalProps) => {

    const {handleGeneralError, handleHasCompletedAssessment} = useContext(ApplicationContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        getLatestAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    async function trackAssessmentOpened(trigger: "automatic"){
        await AnalyticsService.trackUserAction("monthly_assessment_opened", null, {trigger});
    }

    async function getLatestAssessment () {
        try {
            const monthlyAssessment: ApiMonthlyCheckinAnswerV101 = await JourneyApiClient.getMonthlyAssessmentV101();
            const assessmentStep: number = getAssessmentProgress(monthlyAssessment);
            const day = new Date().getDate();
            if((day <= 3 && assessmentStep < 6)|| !props.hasSeenMonthlyAssessment){
                setIsModalOpen(true);
                trackAssessmentOpened("automatic");
            }
        } catch (e) {
            handleGeneralError("Could not fetch latest assessment", e);
        }
    }

    function getAssessmentProgress (monthlyAssessment: ApiMonthlyCheckinAnswerV101): number {
        //In order to allow the user to continue on the last step they left we need to know where they stopped
        //We will return the following step, this will be the first step uncompleted
        //To know which step corresponds to which question, see renderAssessment() in AssessmentModal.tsx
        let progress: number = -1;
        if(monthlyAssessment.stressLevel){
            progress = 1;
        }
        if(monthlyAssessment.feelingsFrequency){
            progress = 2;
        }
        if(monthlyAssessment.manageStressLevel){
            progress = 3;
        }
        if(monthlyAssessment.feelingsFrequencyAffectWork){
            progress = 4;
        }
        if(monthlyAssessment.daysEmotionallyChallenging){
            progress = 5;
        }
        if(monthlyAssessment.priorities !== null && (JSON.parse(monthlyAssessment.priorities)).length > 0){
            //At this point the user has completed the assessment if career and interests have been completed previously
            progress = 6;
        }
        if(progress < 6) handleHasCompletedAssessment(false);
        return progress;
    }

    function handleNextStepNavigation() {
        history.push("/assessment");
        setIsModalOpen(false);
    }

    return(
        <IonModal
            className={`assessment-modal-component fit-content`}
            showBackdrop={true}
            isOpen={isModalOpen && props.monthlyAssessmentIsAllowed}
            initialBreakpoint={.98}
            onWillDismiss={() => setIsModalOpen(false)}>
                <AssessmentWelcomeStep
                    handleNextStepNavigation={handleNextStepNavigation}
                    handleModalDismiss={() =>setIsModalOpen(false)}
                />
        </IonModal>
    )
}
