import * as React from 'react';
import { ApiResourcesContentLanguage, ApiUser, FeatureConfig } from "../utils/ApiTypes";


export interface ApplicationContextType {
    currentUser: ApiUser | null;
    setUserFlags: (flag: string) => void;
    isCurrentUserLoading: boolean;
    setIsAppLoaded: (value: boolean) => void;
    isAppLoaded: boolean;
    refreshUserData: () => Promise<ApiUser | null>;
    initializeCurrentUser: () => Promise<ApiUser | null>;
    isMobileWidth: boolean;
    isMobileApp: boolean | null;
    handleLogout: () => void;
    silentlyRefreshUserData: () => Promise<any>;
    handleGeneralError: (description: string, error: any, header?: string, errorDataContext?: { contextName: string, contextData: any }) => void;
    handleUserError: (header: string, description: string) => void;
    handleApplicationError: (description: string) => void;
    handleGeneralMessage: (header: string, description: string) => void;
    openDownloadMobileAppModal: () => void;
    isProfileMenuOpen: boolean;
    handleToggleProfileMenu: () => void;
    profileStepsCompleted: number;
    totalProfileSteps: number;
    isLanguagesMenuOpen: boolean;
    handleToggleLanguagesMenu: () => void;
    isOldLanguagesMenuOpen: boolean;
    handleToggleOldLanguagesMenu: () => void;
    isMiniLanguageMenuOpen: boolean;
    handleToggleMiniLanguageMenu: () => void;
    isCountriesMenuOpen: boolean;
    handleToggleCountriesMenu: () => void;
    hasCompletedAssessment: boolean;
    handleHasCompletedAssessment: (complete: boolean) => void;
    isDownloadMobileAppModalOpen: boolean;
    appResumeCounter: number;
    language: ApiResourcesContentLanguage | null;
    fullLanguageSupport: boolean;
    changeLanguage: (lang: ApiResourcesContentLanguage) => void;
    openMindfulBreakModal: () => void;
    isMindfulBreakModalOpen: boolean;
    setIsMindfulBreakModalOpen: (open: boolean) => void;
    currentlyPlayingId: string,
    setCurrentlyPlayingId: (id: string) => void,
    isShareRegistrationLinkOpen: boolean;
    setIsShareRegistrationLinkOpen: (open: boolean) => void;
    isInstantHelpSliderOpen: boolean;
    setIsInstantHelpSliderOpen: (open: boolean) => void;
    isResetInProgress: boolean;
    setIsResetInProgress: (inProgress: boolean) => void;
    hasDeveloperRole: boolean;
    setHasDeveloperRole: (canSwitch: boolean) => void;
    needProfileRefresh: boolean;
    setNeedProfileRefresh: (inProgress: boolean) => void;
    featureConfig: FeatureConfig;
    setFeatureConfig: (featureConfig: FeatureConfig) => void;
    isCommunicationsOptInModalOpen: boolean;
    setIsCommunicationsOptInModalOpen: (open: boolean) => void;
    industryCssClass: string;
    setIndustry: (industryClass: string) => void;
    subscribeToDailyJourney: boolean;
    setSubscribeToDailyJourney: (open: boolean) => void;
    hasBenefitsInThisLocale: boolean;
    setHasBenefitsInThisLocale: (open: boolean) => void;
    hasDailyJourneyAvailable: boolean;
    setHasDailyJourneyAvailable: (open: boolean) => void;
    hasCompanyCountries: boolean;
    setHasCompanyCountries: (open: boolean) => void;
    hasWpoContent: boolean;
    setHasWpoContent: (open: boolean) => void;
}

const noop = () => { };
const asyncNoop = async () => { return Promise.resolve(null) };

export const ApplicationContext: React.Context<ApplicationContextType> =
    React.createContext<ApplicationContextType>({
        currentUser: null,
        setUserFlags: noop,
        isCurrentUserLoading: true,
        setIsAppLoaded: noop,
        isAppLoaded: false,
        refreshUserData: asyncNoop,
        initializeCurrentUser: asyncNoop,
        isMobileWidth: true,
        isMobileApp: null,
        handleLogout: noop,
        silentlyRefreshUserData: asyncNoop,
        handleGeneralError: asyncNoop,
        handleUserError: asyncNoop,
        handleApplicationError: asyncNoop,
        handleGeneralMessage: asyncNoop,
        openDownloadMobileAppModal: noop,
        isProfileMenuOpen: false,
        handleToggleProfileMenu: noop,
        profileStepsCompleted: 0,
        totalProfileSteps: 0,
        isLanguagesMenuOpen: false,
        handleToggleLanguagesMenu: noop,
        isOldLanguagesMenuOpen: false,
        isMiniLanguageMenuOpen: false,
        handleToggleOldLanguagesMenu: noop,
        handleToggleMiniLanguageMenu: noop,
        isCountriesMenuOpen: false,
        handleToggleCountriesMenu: noop,
        hasCompletedAssessment: false,
        handleHasCompletedAssessment: noop,
        isDownloadMobileAppModalOpen: false,
        appResumeCounter: 0,
        language: null,
        fullLanguageSupport: true,
        changeLanguage: noop,
        openMindfulBreakModal: noop,
        isMindfulBreakModalOpen: false,
        setIsMindfulBreakModalOpen: noop,
        currentlyPlayingId: '',
        setCurrentlyPlayingId: noop,
        isShareRegistrationLinkOpen: false,
        setIsShareRegistrationLinkOpen: noop,
        isInstantHelpSliderOpen: false,
        setIsInstantHelpSliderOpen: noop,
        isResetInProgress: false,
        setIsResetInProgress: noop,
        hasDeveloperRole: true,
        setHasDeveloperRole: noop,
        needProfileRefresh: false,
        setNeedProfileRefresh: noop,
        featureConfig: { features: {} },
        setFeatureConfig: noop,
        isCommunicationsOptInModalOpen: false,
        setIsCommunicationsOptInModalOpen: noop,
        subscribeToDailyJourney: false,
        setSubscribeToDailyJourney: noop,
        industryCssClass: 'standard',
        setIndustry: noop,
        hasBenefitsInThisLocale: false,
        setHasBenefitsInThisLocale: noop,
        hasDailyJourneyAvailable: false,
        setHasDailyJourneyAvailable: noop,
        hasCompanyCountries: false,
        setHasCompanyCountries: noop,
        hasWpoContent: false,
        setHasWpoContent: noop,
    });
 