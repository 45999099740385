import "./MuxLiveVideoPlayer.scss";

import {FC, useEffect, useState, useContext} from "react";
import MuxPlayer from "@mux/mux-player-react";
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import { useParams } from 'react-router';
import { ApplicationContext } from '../../misc/ApplicationContext';
import {ApiLiveStream} from "../../utils/ApiTypes";
import {VideoComment} from "../VideoComment/VideoComment";
import {IonItemDivider, IonSpinner} from "@ionic/react";
import * as React from "react";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { useTranslation } from 'react-i18next';

interface MuxLiveVideoPlayerProps{
  trackPlayEvent: (liveStream: ApiLiveStream) => {};
  trackPauseEvent: (liveStream: ApiLiveStream) => {};
}

export const MuxLiveVideoPlayer: FC<MuxLiveVideoPlayerProps> = (props: MuxLiveVideoPlayerProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const liveVideoElementRef = React.useRef<any>(null);
  const params = useParams<{id: string}>();
  const {handleApplicationError, currentUser, isMobileApp} = useContext(ApplicationContext);
  const [liveStreamId, setLiveStreamId] = useState<number | null>(null);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [liveStream, setLiveStream] = useState<ApiLiveStream | null>(null);
  const [isWaitingForStreamStart, setIsWaitingForStreamStart] = useState<boolean>(true);
  const [liveStreamStartDate, setLiveStreamStartDate] = useState<Date | string | null>(null);
  const [streamStartsIn15MinutesOrLess, setStreamStartsIn15MinutesOrLess] = useState<boolean | null>(null);
  const pollingTimehandle = React.useRef<NodeJS.Timeout | null>();
  const { t } = useTranslation();

  async function checkForStreamStart(id: number) {
    try{
      const newStreamData: ApiLiveStream = await JourneyApiClient.getLiveStreamInfo(id);
      if(newStreamData.status === 'active') {
        setIsWaitingForStreamStart(false);
        if(newStreamData.oneTimeScheduleDate){
          calculateTimeStart(newStreamData.oneTimeScheduleDate);
        }
        if(liveVideoElementRef && liveVideoElementRef.current){
          liveVideoElementRef.current.play().catch((error: any) => { Sentry.captureException(error.message)});
        }
        if (pollingTimehandle.current) {
          clearInterval(pollingTimehandle.current);
        }
      }
    } catch(e: any) {
      handleApplicationError(e.message);
    }
  }

  useEffect(() => { 
    playVideoPlayer()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isWaitingForStreamStart]);

  useEffect(() => {
    if (pollingTimehandle.current) {
      clearInterval(pollingTimehandle.current);
      pollingTimehandle.current = null;
    }

    if(liveStreamId && liveStream && isWaitingForStreamStart && !isLoading) {
      pollingTimehandle.current = setInterval(() => checkForStreamStart(liveStreamId), 2500);
    }

    return () => {
      if (pollingTimehandle.current) {
        clearInterval(pollingTimehandle.current);
        pollingTimehandle.current = null;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveStreamId, liveStream, isWaitingForStreamStart, isLoading]);

  useEffect(()=> {
    const newStreamId = parseInt(params.id, 10);
    setIsLoading(true);
    setLiveStream(null);
    setLiveStreamId(null);
    setIsWaitingForStreamStart(true);
    setStreamStartsIn15MinutesOrLess(null);
    setLiveStreamStartDate(null);
    if(newStreamId) {
      setLiveStreamId(newStreamId);
      (async () => await getNewStreamData(newStreamId))();
    } else {
      handleApplicationError(`Invalid live stream id: ${newStreamId}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getNewStreamData (newStreamId: number) {
    try{
      const newStreamData: ApiLiveStream = await JourneyApiClient.getLiveStreamInfo(newStreamId);
      setIsWaitingForStreamStart(newStreamData.status !== 'active');
      setLiveStream(newStreamData);
      setIsLoading(false);
      calculateTimeStart(newStreamData.oneTimeScheduleDate!);
    } catch(e: any) {handleApplicationError(e.message)}
  }

  const calculateTimeStart = (date: string) => {
    const minutesToStreamStart: number = getTimeDifferenceFromNowInMinutes(date);
    const streamStartsIn15Minutes = minutesToStreamStart > -16 && minutesToStreamStart <= 45;
    setStreamStartsIn15MinutesOrLess(streamStartsIn15Minutes);
    if(!streamStartsIn15Minutes){
      setLiveStreamStartDate(formatTime(date));
    }
  }

  const getTimeDifferenceFromNowInMinutes = (livestreamScheduleDate: string | Date): number => {
    const startTime: moment.Moment = moment(livestreamScheduleDate);
    const now: moment.Moment = moment.utc();
    return now.diff(startTime, "minutes");
  }

  const formatTime = (liveStreamStartDate: string | Date): string => {
    return moment(liveStreamStartDate).tz(moment.tz.guess()).format("dddd MMM Do - h:mm A");
  }

  async function playVideoPlayer () {
    try {
      if (!isLoading && !isWaitingForStreamStart && liveVideoElementRef && liveVideoElementRef.current) {
        await liveVideoElementRef.current.play();
      }
    } catch (error: any) { Sentry.captureException(error.message);}
  }

  //Pausing video player in mobile as a temporary hack/fix for this bug since controls do not hide automatically when the live video stream autoplays on mobile.
  async function pauseVideoPlayer () {
    try {
      if (liveVideoElementRef && liveVideoElementRef.current && !showControls) {
        await liveVideoElementRef.current.pause();
        setShowControls(true);
      }
    } catch (error: any) { Sentry.captureException(error.message);}
  }

  if(isWaitingForStreamStart && currentUser && liveStream){
    return(
        <div className="mux-live-video-player-component">
          <div className="live-video-player-container">
            <div className="video-loading-container">
              <p className="video-loading-container-text">
                {streamStartsIn15MinutesOrLess !== null ?
                    (streamStartsIn15MinutesOrLess ?
                            t("The event will start in a few minutes")
                            : `${t("Stream starts")} ${liveStreamStartDate}`
                    ) : null
                }
                {(streamStartsIn15MinutesOrLess || streamStartsIn15MinutesOrLess === null) && <IonSpinner className="loading-spinner" name="circular"/>}
              </p>
            </div>
            <div className="text-container">
              <h1 className="title">{liveStream.title}</h1>
              <h4 className="comments">{t("COMMENTS")}</h4>
              <IonItemDivider className="line-divider"/>
              <VideoComment
                  sourceId={liveStream.id}
                  currentUser={currentUser}
                  isLiveStream={true}
              />
            </div>
          </div>
        </div>
    );
  } else if(!isWaitingForStreamStart && currentUser && liveStream) {
    return(
        <div className={`mux-live-video-player-component ${(isMobileApp) ? (showControls ? "" : "hide-controls") : ""}`}>
          <div className="live-video-player-container" onTouchEnd={pauseVideoPlayer}>
            <MuxPlayer
                streamType="ll-live"
                playbackId={liveStream.playbackUrl}
                ref={liveVideoElementRef}
                onPlay={() => props.trackPlayEvent(liveStream)}
                onPause={() => props.trackPlayEvent(liveStream)}
                metadata={{
                  video_id: liveStream.id,
                  video_title: liveStream.title,
                  viewer_user_id: currentUser?.id,
                  player_name: 'live-video-player',
                }}
            />
            <div className="text-container">
              <h1 className="title">{liveStream.title}</h1>
              <h4 className="comments">{t("COMMENTS")}</h4>
              <IonItemDivider className="line-divider"/>
              <VideoComment
                  sourceId={liveStream.id}
                  currentUser={currentUser}
                  isLiveStream={true}
              />
            </div>
          </div>
        </div>
    );
  } else {
    return <div>{t("Loading...")}</div>
  }
}
