import React, {useContext, useEffect} from 'react';
import {IonButton, IonButtons, IonFooter, IonToolbar, isPlatform} from "@ionic/react";
import './FooterComponent.scss';
import {ApplicationContext} from "../../misc/ApplicationContext";
import {PRIVACY_POLICY_URL, PROFILE_PATH} from "../../utils/utils";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import { useTranslation } from 'react-i18next';
import {useLocation} from "react-router-dom";

const FooterComponent: React.FC = () => {
    const {handleLogout, isMobileWidth, currentUser} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const isIos = isPlatform("ios");
    const { t } = useTranslation();
    const location = useLocation();
    const isProfilePage: boolean = location.pathname === PROFILE_PATH;

    useEffect(() => {
        if (!currentUser) {
            // If currentUser is not available, do nothing
            return;
        }

        // Define the script content
        const scriptContent = `
          Canny('identify', {
            appID: '666c7d1aeb78aadfef68abc4',
            user: {
              email: '${currentUser.email}',
              name: '${currentUser.firstName + " " + currentUser.lastName}',
              id: '12345',
              companies: [{
                id: '${currentUser.companyId}',
                name: '${currentUser.company.name}',
              }],
            },
          });
        `;

        // Create a new script element
        const script = document.createElement('script');
        script.innerHTML = scriptContent;

        // Append the script to the document head or body
        document.body.appendChild(script);
      }, [isComponentVisible]);

    return (
        <IonFooter className="footer-component">
            <IonToolbar className="footer-toolbar-component">
                <IonButtons className="buttons-container" slot="start">
                    {currentUser && <IonButton className="footer-button button-small" fill="clear" onClick={handleLogout}>
                        {t("Log out")}
                    </IonButton>}

                    <IonButton className="footer-button button-small-variant" fill="clear" href={PRIVACY_POLICY_URL} target="_blank">
                        {t("Privacy Policy")}
                    </IonButton>

                    <a data-canny-link target='_blank' className="button-small-variant" href="https://journey-live.canny.io/feature-requests">
                        {t("Give feedback")}
                    </a>

                    {isIos && isMobileWidth && currentUser && isProfilePage &&
                        <DeleteAccount isMobileWidth={isMobileWidth} isComponentVisible={isComponentVisible}/>
                    }
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    );
}

export default FooterComponent;