import {IonButton} from "@ionic/react";
import greenCheckmark from "../../assets/images/green-circle-checkmark.svg";
import * as React from "react";
import { useTranslation } from 'react-i18next';
import {HOME_PATH} from "../../utils/utils";
import {useHistory} from "react-router-dom";
import { Feature, FeatureShowContents } from "../Feature/Feature";
import { TalkToSomeone } from "../TalkToSomeone/TalkToSomeone";
import { User } from "../../utils/ApiTypes";

interface assessmentFinishedStepProps {
    handleAssessmentCompleted: () => void;
    currentUser: User;
    isMobileWidth: boolean;
    isComponentVisible: boolean;
    isAssessmentNegative: boolean;
}

export const AssessmentFinishedStep: React.FC<assessmentFinishedStepProps> = (props: assessmentFinishedStepProps) => {

    const { t } = useTranslation();
    const history = useHistory();

    function handleGoToHomeButtonPressed() {
        props.handleAssessmentCompleted();
        history.push(HOME_PATH);
    }

    return(
        <div className="assessment-finished-step">
            <div className="journey-checkmark-container"><img alt={"Checkmark Complete"} src={greenCheckmark}/></div>
            <div className="assessment-title header-4">{t("Assessment Complete!")}</div>
            <Feature feature="proactive-clinical-care" showContents={FeatureShowContents.whenDisabled}>
                <div className="assessment-text body-medium">{t("Keep an eye out for more personalized content based on your responses.")}</div>
            </Feature>
            <Feature feature="proactive-clinical-care">
                <div className="assessment-finished-text body-medium">{props.isAssessmentNegative ? t("Everyone has tough days sometimes. But we noticed from your assessment that you’ve been feeling pretty down this month. Our Master's Level Clinicians are available 24/7 to have a free, confidential conversation about your mental health.")
                : t("Congratulations on completing your assessment! Your answers will help us provide you with more personalized content.")}
                </div>
            {!props.isAssessmentNegative && <div className="assessment-finished-text body-medium">{t("Keep in mind, our Master's Level Clinicians are available 24/7 to have a free, confidential conversation about your mental health.")}
            </div>}
            </Feature>
            <Feature feature="proactive-clinical-care">
                <TalkToSomeone
                    className={"assessment-talk-to-someone"}
                    user={props.currentUser}
                    isMobileWidth={props.isMobileWidth}
                    showDescription={false}
                    showDescriptionInPopover={true}
                    isCondensed={false}
                    subtitle={t("All support is free and confidential.")}
                    isComponentVisible={props.isComponentVisible} />
            </Feature>
            <div className="assessment-continue-button-container">
                <IonButton className="assessment-continue-button button-medium-variant" onClick={handleGoToHomeButtonPressed}>
                    {t("Go to Home")}
                </IonButton>
            </div>
        </div>
    )
}