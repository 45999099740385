import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {IonContent, IonPage} from "@ionic/react";
import "./MyListPage.scss";
import {JourneyApiClient} from '../../utils/JourneyApiClient';
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {VideoContentItemResponse} from "../../utils/ApiTypes";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import AnalyticsService from "../../misc/AnalyticsService";
import {MuxVideoPlayer} from "../../components/MuxVideoPlayer/MuxVideoPlayer";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";

export const MyListPage: React.FC = () => {
    const [muxVideo, setMuxVideo] = useState<VideoContentItemResponse|null>(null);
    const [isVideoContentLoading, setIsVideoContentLoading] = useState<boolean>(true);
    const [savedList, setSavedList] = useState<VideoContentItemResponse[]>([]);
    const {handleGeneralError, handleApplicationError, currentUser} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(isComponentVisible){
            getSavedVideos();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);


    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("my_list_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("my_list_video_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("my_list_video_checkpoint", location.pathname, eventProperties);
    }

    const getSavedVideos = async () => {
        if (!currentUser) return;
        setIsVideoContentLoading(true);
        try {
            const savedItems = await JourneyApiClient.getSavedVideos();
            const videos = savedItems.filter(video => video.type === 'video') as VideoContentItemResponse[];
            if(videos.length === 0) {
                handleApplicationError("Add videos to your list");
                history.push("/");
            }
            setSavedList(videos);
            setMuxVideo(videos[currentIndex]);
            setIsVideoContentLoading(false);
        } catch (ex) {
            handleGeneralError("Could not get saved videos", ex);
            setIsVideoContentLoading(false);
            history.push("/");
        }
    }

    const handleEndVideo = async () => {
        const nextIndex = currentIndex + 1 === savedList.length ? 0 : currentIndex + 1;
        setCurrentIndex(nextIndex);
        setMuxVideo(savedList[nextIndex]);
    }

    const handleError = (description: string) => {
        handleApplicationError(description);
    }

    return(
        <IonPage className="my-list-page-component">
            <IonContent className="my-list-content">
                <div className='my-list-container'>
                    {(isVideoContentLoading) ?
                        <PleaseWait/> : (muxVideo) &&
                        <MuxVideoPlayer
                            isPublicVideo={true}
                            autoplay={true}
                            videoId={muxVideo.id}
                            duration={muxVideo.duration}
                            isLoading={isVideoContentLoading}
                            isComponentVisible={isComponentVisible}
                            isInitialMuted={true}
                            muxPlaybackId={muxVideo.muxPlaybackId}
                            muxVideoId={muxVideo.muxVideoId}
                            title={muxVideo.title}
                            currentUser={currentUser}
                            trackCuePointEvent={trackCuePointEvent}
                            trackPlayEvent={trackPlayEvent}
                            onVideoEnd={handleEndVideo}
                            trackPauseEvent={trackPauseEvent}
                            handleApplicationError={handleError}
                            numberOfRecommendationsToShow={0}
                            hideRecommendations={true}/>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
};
