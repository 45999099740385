import './SignUpForm.scss';
import React, {useContext} from 'react';
import {useState} from "react";
import { useHistory } from "react-router-dom";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {JourneyApiClient} from '../../utils/JourneyApiClient';
import {ApiCompany} from "../../utils/ApiTypes";
import {IonItem, IonInput, IonButton, IonLabel} from "@ionic/react";
// import {HOME_PATH} from "../../utils/utils";
import AnalyticsService from "../../misc/AnalyticsService";
import { useTranslation } from 'react-i18next';

interface Props {
    company: ApiCompany;
    realmDiscovery?: boolean;
    title: string;
    emailType: 'company' | 'preferred';
}

export const SignUpForm: React.FC<Props> = (props) => {

    const history = useHistory();
    const {handleUserError, handleGeneralMessage, isMobileApp, language} = useContext(ApplicationContext);

    //register fields
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [workEmail, setWorkEmail] = useState<string | null>(null);
    const [preferredEmail, setPreferredEmail] = useState<string | null>(null);
    const [realmDiscoverySubmitted, setRealmDiscoverySubmitted] = useState<boolean>(false);
    const [employeeId, setEmployeeId] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const submitForm = async () => {
        if((workEmail && validateEmail(workEmail)) || (preferredEmail && validateEmail(preferredEmail))) {
            setIsLoading(true);
            if(props.realmDiscovery && !realmDiscoverySubmitted) {
                try {
                    const response = await JourneyApiClient.getRealmDiscovery(workEmail ?? preferredEmail ?? '');
                    await AnalyticsService.trackUserAction("signup_realm_discovery");
                    if(response.realm === "sso") {
                        history.push(`/auth/sso/${props.company.code}`);
                    }
                    else if(response.realm === "workos-sso") {
                        history.push(`/auth/sso/${props.company.code}`);
                    }
                    else if (response.realm === "code") {
                        //code login
                        // history.replace(HOME_PATH);
                        setIsLoading(false);
                        handleGeneralMessage(t("Please login"), t("User already registered. Use your email to login."));
                    }
                } catch (error: any) {
                    //user does not exist
                    setIsLoading(false);
                    setRealmDiscoverySubmitted(true);
                }
            } else {
                try {
                    const response = await JourneyApiClient.registerUser({
                        firstName: firstName.trim(),
                        lastName: lastName.trim(),
                        email: workEmail?.trim() ?? null,
                        preferredEmail: preferredEmail?.trim() ?? null,
                        employeeId: employeeId,
                        dateOfBirth: new Date(dateOfBirth),
                        companyId: props.company.id,
                        isDeleted: false,
                        country: language?.countryId ?? 'US',
                    });
                    await AnalyticsService.trackUserAction("signup_user_registration");
                    //register was successful
                    setIsLoading(false);
                    handleGeneralMessage(t("All set"), t(response));
                    setFormSubmitted(true);
                } catch (error: any) {
                    handleUserError(t("Error"),`${t("Your registration was unsuccessful.")} ${error?.message ? t(error.message) : ''}`);
                    setIsLoading(false);
                }
            }
        }
    };

    const validateEmail = (email: string | null) => {
        if(!email) return false;
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) ? true : false;
    };

    const submitDisabled = (props.realmDiscovery && !realmDiscoverySubmitted) ? !validateEmail(workEmail) :
        !firstName ? true : false
        || !lastName? true : false
        || (!props.realmDiscovery && props.company.registrationMatchField === 'employeeId' && !employeeId ? true : false)
        || (!props.realmDiscovery && props.company.registrationMatchField === 'dateOfBirth' && !dateOfBirth ? true : false);

    if(formSubmitted) {
        return (
            <div className='sign-up-form-component'>
                <h1 className='sign-up-title'> {props.title} </h1>
            </div>
        );
    } else {
        return (
            <div className='sign-up-form-component'>
                    <h1 className='sign-up-title'> {props.title} </h1>

                    {(!props.realmDiscovery || realmDiscoverySubmitted) &&
                        <div className="row">
                            <IonItem className="name" mode="md" fill="outline">
                                <IonLabel position="floating">{`${t('First Name')}*`}</IonLabel>
                                <IonInput className={"button-medium"} placeholder={t('First Name') ?? ""} value={firstName} onIonChange={e => setFirstName(e.detail.value!)} />
                            </IonItem>

                            <IonItem className="name" mode="md" fill="outline">
                                <IonLabel position="floating">{`${t('Last Name')}*`}</IonLabel>
                                <IonInput className={"button-medium"} placeholder={t('Last Name') ?? ""} value={lastName} onIonChange={e => setLastName(e.detail.value!)} />
                            </IonItem>
                        </div>
                    }

                    {props.emailType === 'company' && <IonItem className="field" mode="md" fill="outline">
                        <IonLabel position="floating">{`${t('Email')}*`}</IonLabel>
                        <IonInput className={"button-medium"} placeholder={t('Email') ?? ""} type="email" autocomplete="email" inputmode="email" value={workEmail} onIonChange={e => setWorkEmail(e.detail.value!)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" />
                    </IonItem>}

                    {props.emailType === 'preferred' && <IonItem className="field" mode="md" fill="outline">
                        <IonLabel position="floating">{`${t('Email')}*`}</IonLabel>
                        <IonInput className={"button-medium"} placeholder={t('Email') ?? ""} type="email" autocomplete="email" inputmode="email" value={preferredEmail} onIonChange={e => setPreferredEmail(e.detail.value!)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" />
                    </IonItem>}

                    {!props.realmDiscovery && props.company.registrationMatchField === 'employeeId' &&
                        <IonItem className="field" mode="md" fill="outline">
                            <IonLabel position="floating">{`${t('Employee Id')}*`}</IonLabel>
                            <IonInput className={"button-medium"} placeholder={t('Employee Id') ?? ""} value={employeeId} onIonChange={e => setEmployeeId(e.detail.value!)} />
                        </IonItem>
                    }

                    {!props.realmDiscovery && props.company.registrationMatchField === 'dateOfBirth' &&
                        <IonItem className="field" mode="md" fill="outline">
                            <IonLabel position="floating">{`${t('Date of Birth')}*`}</IonLabel>
                            <IonInput className={"button-medium"} placeholder={t('Date of Birth') ?? ""} type="date" value={dateOfBirth} onIonChange={e => setDateOfBirth(e.detail.value!)} />
                        </IonItem>
                    }

                    <IonButton type='submit' className='paper-submit-button-variation' onClick={submitForm} disabled={submitDisabled || isLoading} >
                        {t('submit')}
                    </IonButton>
            </div>
        );
    }
};
