import "./AssessmentComponent.scss";
import * as React from "react";
import {ApiMonthlyCheckinAnswerV101, User} from "../../utils/ApiTypes";
import {useContext, useState} from "react";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {AssessmentSteps} from "./AssessmentSteps";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

interface assessmentComponentProps {
    currentUser: User;
}

export const AssessmentComponent: React.FC<assessmentComponentProps> = (props: assessmentComponentProps) => {

    const {handleGeneralError, silentlyRefreshUserData, handleHasCompletedAssessment} = useContext(ApplicationContext);
    const [assessmentProgress, setAssessmentProgress] = useState<number>(-1);
    const queryClient = useQueryClient();

    async function getLatestAssessment(): Promise<ApiMonthlyCheckinAnswerV101 | undefined> {
        try{
            const monthlyAssessment: ApiMonthlyCheckinAnswerV101 = await JourneyApiClient.getMonthlyAssessmentV101();
            getAssessmentProgress(monthlyAssessment);
            return monthlyAssessment;
        } catch (e) {
            handleGeneralError("Could not fetch latest assessment", e);
        }
    }
    useQuery<Promise<ApiMonthlyCheckinAnswerV101 | undefined>, unknown, ApiMonthlyCheckinAnswerV101>
    (["getMonthlyAssessmentV101"], getLatestAssessment);

    function getAssessmentProgress (monthlyAssessment: ApiMonthlyCheckinAnswerV101): number {
        //In order to allow the user to continue on the last step they left we need to know where they stopped
        //We will return the following step, this will be the first step uncompleted
        //To know which step corresponds to which question, see renderAssessment() in AssessmentModal.tsx
        let progress: number = 0;
        if(monthlyAssessment.stressLevel){
            progress = 1;
        }
        if(monthlyAssessment.feelingsFrequency){
            progress = 2;
        }
        if(monthlyAssessment.manageStressLevel){
            progress = 3;
        }
        if(monthlyAssessment.feelingsFrequencyAffectWork){
            progress = 4;
        }
        if(monthlyAssessment.daysEmotionallyChallenging){
            progress = 5;
        }
        if(monthlyAssessment.priorities !== null && (JSON.parse(monthlyAssessment.priorities)).length > 0){
            //At this point the user has completed the assessment if career and interests have been completed previously
            progress = 6;
        }
        if(progress === 6 && props.currentUser.career !== null){
            progress = 7;
        }
        if(progress === 7 && props.currentUser.interests !== null){
            progress = 8;
        }
        setAssessmentProgress(progress);
        handleAssessmentCompleted(progress >= 6);
        return progress;
    }

    const assessmentProgressMutation = useMutation({
        mutationFn: ({formValues}: { formValues: ApiMonthlyCheckinAnswerV101}) => {
            return JourneyApiClient.saveMonthlyAssessmentV101(formValues);
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getMonthlyAssessmentV101'] })
            await silentlyRefreshUserData();
        }
    })

    async function submitAssessmentProgress(formValues: ApiMonthlyCheckinAnswerV101){
        try{
            assessmentProgressMutation.mutate({formValues});
        } catch (e) {

        }
    }

    function handleAssessmentCompleted(isCompleted: boolean) {
        handleHasCompletedAssessment(isCompleted);
    }

    return(
        <div className="assessment-component">
            <AssessmentSteps
                progress={assessmentProgress}
                submitAssessmentProgress={submitAssessmentProgress}
                currentUser={props.currentUser}
                hasAnsweredCareerAndInterests={props.currentUser.interests !== null && props.currentUser.career !== null}
                onMonthlyAssessmentCompleted={() => handleAssessmentCompleted(true)}/>
        </div>
    )
}
