import * as React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import "./SideMenu.scss";
import {
    IonContent, IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenu,
    IonToolbar
} from "@ionic/react";
import {
    HOME_PATH,
    RESOURCES_PATH,
    DAILY_JOURNEY_PATH,
    SEARCH_PATH,
    SETTINGS_PATH,
    DEVELOPER_PATH,
    LANGUAGES_PATH,
    PROFILE_PATH,
    BENEFITS_PATH,
} from "../../utils/utils";
import { settings, globeOutline, cogSharp } from 'ionicons/icons';
import { ApplicationContext } from "../../misc/ApplicationContext";
import HOME from "../../assets/images/home-icon.svg";
import BROWSE from "../../assets/images/search-sidemenu-icon.svg";
import DAILYJOURNEY from "../../assets/images/daily-journey-icon.svg";
import RESOURCES from "../../assets/images/resources-icon.svg";
import BENEFITS from "../../assets/images/benefits-icon.svg";
import LOGOUT from "../../assets/images/logout-icon.svg";
import HELPNOW from "../../assets/images/help-now-icon.svg";
import ForwardArrow from "../../assets/images/forward-full-arrow.svg";
import { useTranslation } from 'react-i18next';
import { ProfileProgressBar } from "../Profile/ProfileProgressBar";


interface SideMenuProps {
    hideMenuButtons?: boolean;
}

/**
 * This is only visible on small devices via CSS media queries.
 */
export const SideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {

    const { t } = useTranslation();
    const menuRef = React.useRef<HTMLIonMenuElement>(null);
    const { profileStepsCompleted, totalProfileSteps, handleLogout, fullLanguageSupport, currentUser, setIsInstantHelpSliderOpen, isMobileApp, hasDeveloperRole, hasBenefitsInThisLocale, hasWpoContent } = useContext(ApplicationContext);
    const history = useHistory();

    const onClick = () => {
        if (menuRef.current) {
            menuRef.current.close(true);
        }
    }

    const handleToolbarProfileImageClick = async () => {
        if (menuRef.current) {
            menuRef.current.close(true);
        }
        history.push(PROFILE_PATH);
    }

    const onHelpClick = () => {
        setIsInstantHelpSliderOpen(true);
        onClick();
    }

    return (
        <IonMenu side="start" className="side-menu-component" contentId="main-content" menuId="side-menu" ref={menuRef}>
            {!props.hideMenuButtons &&
                <IonHeader className="side-menu-header">
                    <IonToolbar className="side-menu-toolbar">
                        <div className={"toolbar-image-container"}>
                            <ProfileProgressBar onProfileClick={handleToolbarProfileImageClick} />
                        </div>
                        <div className={"toolbar-user-name h6-bold"}>{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
                    </IonToolbar>
                </IonHeader>
            }
            <IonContent>
                {!props.hideMenuButtons && fullLanguageSupport &&
                    <>
                        <IonItem className="side-menu-item" routerLink={PROFILE_PATH} onClick={onClick} detail={false}>
                            <IonLabel className="side-menu-text button-small">{t("Complete Profile")} {`(${Math.round((profileStepsCompleted / totalProfileSteps) * 100)}%)`}</IonLabel>
                            <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                        </IonItem>
                        <IonItem className="side-menu-item" routerLink={HOME_PATH} onClick={onClick} detail={false}>
                            <IonIcon className="side-menu-icon" slot="start" icon={HOME} color="default" />
                            <IonLabel className="side-menu-text button-small">{t("For You")}</IonLabel>
                            <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                        </IonItem>
                        <IonItem className="side-menu-item" routerLink={SEARCH_PATH} onClick={onClick} detail={false}>
                            <IonIcon className="side-menu-icon" slot="start" icon={BROWSE} color="default" />
                            <IonLabel className="side-menu-text button-small">{t('search')}</IonLabel>
                            <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                        </IonItem>
                        <IonItem className="side-menu-item" routerLink={DAILY_JOURNEY_PATH} onClick={onClick} detail={false}>
                            <IonIcon className="side-menu-icon" slot="start" icon={DAILYJOURNEY} color="default" />
                            <IonLabel className="side-menu-text button-small">{t("Daily Journey")}</IonLabel>
                            <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                        </IonItem>
                        {hasWpoContent &&
                        <IonItem className="side-menu-item" routerLink={RESOURCES_PATH} onClick={onClick} detail={false}>
                            <IonIcon className="side-menu-icon" slot="start" icon={RESOURCES} color="default" />
                            <IonLabel className="side-menu-text button-small">{t('Resources')}</IonLabel>
                            <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                        </IonItem>}
                    </>}
                    {!props.hideMenuButtons && hasBenefitsInThisLocale &&
                        <IonItem className="side-menu-item" routerLink={BENEFITS_PATH} onClick={onClick} detail={false}>
                            <IonIcon className="side-menu-icon" slot="start" icon={BENEFITS} color="default" />
                            <IonLabel className="side-menu-text button-small">{t('benefits')}</IonLabel>
                            <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                        </IonItem>
                    }
            </IonContent>
            <IonFooter className="side-menu-footer">
                {currentUser?.company?.countries && currentUser?.company?.countries.length > 0 &&
                    <IonItem className="side-menu-item top" routerLink={LANGUAGES_PATH} onClick={onClick} detail={false}>
                        <IonIcon className="side-menu-icon" slot="start" icon={globeOutline} color="default" />
                        <IonLabel className="side-menu-text button-small">{t('Country / Language')}</IonLabel>
                        <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                    </IonItem>
                }
                {currentUser &&
                    <IonItem className="side-menu-item top" routerLink="/" onClick={onHelpClick} detail={false}>
                        <IonIcon className="side-menu-icon" slot="start" icon={HELPNOW} color="default" />
                        <IonLabel className="side-menu-text button-small instant-help-label">{t("Help Now")}</IonLabel>
                        <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                    </IonItem>
                }

                {hasDeveloperRole &&
                    <IonItem className="side-menu-item top" routerLink={DEVELOPER_PATH} detail={false} onClick={onClick}>
                        <IonIcon className="side-menu-icon" slot="start" icon={cogSharp} color="default" />
                        <IonLabel className="side-menu-text button-small">{t('developer')}</IonLabel>
                        <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                    </IonItem>
                }

                {isMobileApp &&
                    <IonItem className="side-menu-item top" routerLink={SETTINGS_PATH} onClick={onClick} detail={false}>
                        <IonIcon className="side-menu-icon" slot="start" icon={settings} color="default" />
                        <IonLabel className="side-menu-text button-small">{t('settings')}</IonLabel>
                        <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                    </IonItem>
                }
                <IonItem className="side-menu-item" routerLink="/" onClick={handleLogout} detail={false}>
                    <IonIcon className="side-menu-icon" slot="start" icon={LOGOUT} color="default" />
                    <IonLabel className="side-menu-text button-small">{t("Log out")}</IonLabel>
                    <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow} />
                </IonItem>
            </IonFooter>
        </IonMenu>
    )
}
