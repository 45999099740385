import * as React from 'react';
import { useEffect, useState} from "react";
import "./TopicComponent.scss";
import { useTranslation } from 'react-i18next';
import { ApiAsset, ApiCollection, MediaType } from '../../utils/ApiTypes';
import ContentGrid from '../ContentGrid/ContentGrid';


type TopicsComponentParams = {
    content: ApiCollection[];
    contentTypeFilter: string;
    contentCount: (map: Map<string, number>) => void;
};

export const TopicComponent: React.FC<TopicsComponentParams> = (props: TopicsComponentParams) => {

    const { t } = useTranslation();
    const [showOnlyOneGrid, setShowOnlyOneGrid] = useState<number | null>(null);
    const [contentMap, setContentMap] = useState<Map<string, ApiAsset[]>>();

    useEffect(() => {
        categorizeContent(props.content);
    }, [props.content]);

    function handleShowOrHideGrid(seeAllButton: boolean, index: number){
        if(seeAllButton){
            setShowOnlyOneGrid(index);
        } else {
            setShowOnlyOneGrid(null);
        }
    }

    function categorizeContent(collections: ApiCollection[]){
        const allItems = collections.flatMap(collection => collection.items);
        const allVideos: ApiAsset[] = allItems.filter(item => item.type === MediaType.Video);
        const allAudios: ApiAsset[] = allItems.filter(item => item.type === MediaType.Audio);
        const allArticles: ApiAsset[] = allItems.filter(item => item.type === MediaType.Article);
        setContentMap(new Map([
            [t("Video"), allVideos],
            [t("Audio"), allAudios],
            [t("Articles"), allArticles]
        ]));

        props.contentCount(new Map([
            [t("Video"), allVideos.length],
            [t("Audio"), allAudios.length],
            [t("Articles"), allArticles.length]
        ]))
    }

    function renderCollections(){
        switch(props.contentTypeFilter) {
            case t("Highlights"):
                //Highlights are the first 8 items of each collection
                return props.content.map((collection: ApiCollection, index: number) => {
                    if(typeof showOnlyOneGrid === 'number' && showOnlyOneGrid !== index) return null;
                    else{
                        return (
                            <div className={"topic-content-grid-wrapper"}>
                                <div key={index} className={"topic-content-grid-container"}>
                                    <ContentGrid 
                                        content={collection.items} 
                                        maxItems={8}
                                        handleClickSeeButton={(seeAll: boolean) => handleShowOrHideGrid(seeAll, index)}
                                        contentHeader={collection.title}/>
                                </div>
                                <hr className={"horizontal-hr"}/>
                            </div>
                        )
                    }
                })
            default:
                if(contentMap && contentMap.get(props.contentTypeFilter)){
                    return (
                        <div className={"topic-content-grid-wrapper"}>
                            <div className={"topic-content-grid-container"}>
                                <ContentGrid content={contentMap.get(props.contentTypeFilter) || []}/>
                            </div>
                            <hr className={"horizontal-hr"}/>
                        </div>
                    )
                }
                return null;
        }
    }


    return(
        <div className={"topics-component"}>
            {props.content.length > 0 ? 
                props.content.length > 1 ? renderCollections() :  
                    <div className={"topic-content-grid-wrapper"}>
                        {/* Only one Collection, this only happens on search */}
                        <div className={"topic-content-grid-container"}>
                            <ContentGrid 
                                content={props.content[0].items} />
                        </div>
                        <hr className={"horizontal-hr"}/>
                    </div>
                : <div>{/*No Collection to display*/}</div>
            }

        </div>
    )
};