import * as React from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import "./MiniLanguageMenuList.scss";
import {
    IonIcon,
    IonItem,
    IonList
} from "@ionic/react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import Check from "../../assets/images/check-grey.svg";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { ApiResourcesContentLanguage } from "../../utils/ApiTypes";
import "flag-icons/css/flag-icons.min.css";

interface Props {
    sendToHome: boolean;
    companyId: number | undefined;
}

export const MiniLanguageMenuList: React.FC<Props> = (props: Props) => {

    const { isMobileWidth, language, changeLanguage, handleToggleMiniLanguageMenu, currentUser } = useContext(ApplicationContext);
    const [languages, setLanguages] = useState<ApiResourcesContentLanguage[]>([]);
    const history = useHistory();

    function compareLanguages(a: ApiResourcesContentLanguage, b: ApiResourcesContentLanguage) {
        // sort by country first, preferring:
        //    current county (if any), then
        //    US first (if not current), then
        //    others by county code

        // within a country, sort by abbreviation

        let comparison = 0;

        if (language?.countryId) {
            if (a.countryId === language.countryId && b.countryId !== language.countryId) {
                comparison = -1;
            } else if (b.countryId === language.countryId && a.countryId !== language.countryId) {
                comparison = 1;
            }
        }

        if (comparison === 0) {
            if (a.countryId !== b.countryId) {
                if (a.countryId === "US") {
                    comparison = -1;
                }
                else if (b.countryId === "US") {
                    comparison = 1;
                }
                else {
                    comparison = a.countryId.localeCompare(b.countryId);
                }
            }
        }

        if (comparison === 0) {
            comparison = a.abbreviation.localeCompare(b.abbreviation);
        }

        return comparison;
    }

    function handleLanguageClick(lang: ApiResourcesContentLanguage): void {
        changeLanguage(lang);
        if (props.sendToHome) history.push("/");
        handleToggleMiniLanguageMenu();
    }

    function getClassForLanguage(iLanguage: ApiResourcesContentLanguage) {
        return `${!isMobileWidth && language?.abbreviation === iLanguage.abbreviation ? 'selected' : ''}  menu-item`;
    }

    useEffect(() => {
        (async function () {
            const response = await JourneyApiClient.getResourcesLanguages(currentUser?.companyId || props.companyId);
            const countryLanguages = response.filter(lang => lang.countryId); // avoid "base" locales
            countryLanguages.sort(compareLanguages);
            setLanguages(countryLanguages);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, currentUser]);

    return (
        <IonList className='mini-language-menu-list-component'>
            {
                languages.map((iLanguage, index) => {
                    return (
                        <IonItem key={index} className={getClassForLanguage(iLanguage)} onClick={() => handleLanguageClick(iLanguage)}>
                            <div key={index} className={`caption language-name`}>
                                <span className={`flag fi fi-${iLanguage?.alpha2CodeISO ?? "us"}`} />
                                {iLanguage.localizedCountryName} - {iLanguage.localizedLanguageName}
                            </div>
                            {(isMobileWidth && language?.abbreviation === iLanguage.abbreviation) &&
                                <IonIcon key={index} className="menu-icon" slot="end" icon={Check} color="default" />
                            }
                        </IonItem>
                    )
                })
            }
        </IonList>
    )
}
