import "./MiniLanguageMenu.scss";
import * as React from "react";
import { MiniLanguageMenuList } from "./MiniLanguageMenuList";

interface Props {
    rightMargin: number | null;
    sendToHome: boolean;
    companyId: number | undefined;
}

export const MiniLanguagesMenu: React.FC<Props> = (props: Props) => {

    return (
        <div className='mini-language-menu-component' style={{ right: `${props.rightMargin ? `${props.rightMargin}px` : '50%'}` }}>
            <MiniLanguageMenuList sendToHome={props.sendToHome} companyId={props.companyId} />
        </div>
    )
}
