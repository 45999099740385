import { format, differenceInDays, add } from 'date-fns'
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { useContext, useEffect, useState} from "react";
import Great from "../../assets/images/great.svg";
import Good from "../../assets/images/good.svg";
import Okay from "../../assets/images/okay.svg";
import Bad from "../../assets/images/bad.svg";
import Check from "../../assets/images/check.svg";
import "./CheckinGraph.scss";
import { LineChart, XAxis, Tooltip, CartesianGrid, Line, ResponsiveContainer, YAxis } from 'recharts';
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import SVG from 'react-inlinesvg';
import { ApplicationContext } from '../../misc/ApplicationContext';
import AnalyticsService from '../../misc/AnalyticsService';
import {useHistory, useLocation} from "react-router-dom";
import {HOME_PATH} from "../../utils/utils";
import { useTranslation } from 'react-i18next';

export type CheckinGraphProps = {
    className?: string;
    onCheckin?: (state: CheckinState, feelingRating?: number) => void;
    openLoginModal?: () => void;
}

export type CheckinState = 'checkedin' | 'not-checkedin' | 'checkedin-previously';

type CheckinDataNode = {
    label: string;
    value: number | null;
    previousValue?: number | null;
}

const Beige: string = "#F7F2ED";
const Green: string = "#4EDED6";

export function CheckinGraph(props: CheckinGraphProps) {

    const [checkins, setCheckins] = useState<Array<CheckinDataNode>>([]);
    const [checkedInToday, setCheckedInToday] = useState<boolean>(false);
    const isComponentVisible = useIsComponentVisible();
    const {appResumeCounter, currentUser, language} = useContext(ApplicationContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [twoOrMoreDataPointsExists, setTwoOrMoreDataPointsExists] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    async function fetchLastThirtyDayCheckin() {
        const response = await JourneyApiClient.getLastSelectedDailyCheckins(15);
        const firstNonNullIndex = response.findIndex(r => !!r);
        const responseToShow = response.slice(Math.min(firstNonNullIndex, 15));
        const checkinDataNodes: Array<CheckinDataNode> = responseToShow.map(checkin => ({ label: checkin?.createdAt ? format(new Date(checkin.createdAt), 'MM/dd') : '', value: checkin?.feelingRating ?? null}));
        if (responseToShow[0]) {
            checkinDataNodes[0].label = `${t(`${(differenceInDays(new Date(), new Date(responseToShow[0].createdAt!)) + 1)}-days-ago`)}`
        }

        let dataPointsCount = 0;
        if (firstNonNullIndex > -1) {
            let lastCheckinFeeling: number | null = 0;
            let rollingDate = new Date(response[firstNonNullIndex]!.createdAt!);
            for (let i = 0; i < checkinDataNodes.length; i++) {

                if (checkinDataNodes[i].value === null) {
                    checkinDataNodes[i] = {
                        value: null,
                        label: format(rollingDate, 'MM/dd'),
                        previousValue: lastCheckinFeeling
                    }

                    if (!!checkinDataNodes[i - 1]?.value) {
                        checkinDataNodes[i - 1].previousValue = checkinDataNodes[i - 1].value;
                    }
                } else {
                    if (checkinDataNodes[i - 1]?.value === null) {
                        checkinDataNodes[i].previousValue = checkinDataNodes[i].value;
                    }
                    lastCheckinFeeling = checkinDataNodes[i].value;
                    dataPointsCount++;
                }
                rollingDate = add(rollingDate, { days: 1 });
            }
        }

        checkinDataNodes[checkinDataNodes.length - 1].label = `${t('today')}`;
        setTwoOrMoreDataPointsExists(dataPointsCount > 1);
        setCheckins(checkinDataNodes);
        const initiallyCheckedIn = responseToShow[responseToShow.length - 1];
        setCheckedInToday(initiallyCheckedIn !== null);
        if (props.onCheckin) {
            if (initiallyCheckedIn) {
                props.onCheckin('checkedin-previously', initiallyCheckedIn.feelingRating!);
            } else {
                props.onCheckin('not-checkedin');
            }
        }

        setLoading(false);
    }

    /**
     * IF the URL contains the 'auto_checkin=N' query parameter, then issue a checkin and clear the query string
     */
    async function processAutoCheckinUrl() {
        let autoChecking = new URLSearchParams(history.location.search.split('?').pop()).get("auto_checkin");
        const autoCheckingValue = parseInt(autoChecking || "", 10);
        if(!isNaN(autoCheckingValue)) {
            setLoading(true);
            try {
                await checkin(autoCheckingValue);
            } catch {
                // Do nothing
            }
            setLoading(false);
            history.replace(HOME_PATH);
        }
    }

    async function checkin(feelingRating: number) {
        if(!currentUser && props.openLoginModal){
            props.openLoginModal()
        } else {
            try {
                let timeOffsetInMilliseconds = (new Date().getTimezoneOffset() * 60 * 1000);
                await JourneyApiClient.saveDailyCheckinAnswersV2({
                    feelingRating,
                    otherPriority: null,
                    priorities: null
                }, timeOffsetInMilliseconds);
                const newCheckins = checkins.slice(0);
                newCheckins[newCheckins.length - 1] = {
                    label: `${t('today')}`,
                    value: feelingRating
                };
                setCheckins(newCheckins);
                setCheckedInToday(true);
                AnalyticsService.trackUserAction("hp_checkin", location.pathname,
                    {feeling_rating: feelingRating});
                if (props.onCheckin) {
                    props.onCheckin('checkedin', feelingRating);
                }
            } catch (ex) {

            }
        }
    }

    /**
     * Renders the y axis ticks
     * @param param0
     * @returns
     */
    function GraphLabel({ x, y, payload }: { x: number, y: number, payload: any}) {

        switch (payload.value) {
            case 2:
                return <SVG className='y-axis-label' onClick={() => checkin(2)} x={x-30} y={y-13} width={30} height={26} src={Bad} />;
            case 3:
                return <SVG className='y-axis-label' onClick={() => checkin(3)} x={x-30} y={y-13} width={30} height={26} src={Okay} />;
            case 4:
                return <SVG className='y-axis-label' onClick={() => checkin(4)} x={x-30} y={y-13} width={30} height={26} src={Good} />;
            case 5:
                return <SVG className='y-axis-label' onClick={() => checkin(5)} x={x-30} y={y-13} width={30} height={26} src={Great} />;
            default:
                return <SVG x={x-20} y={y-10} width={20} height={20} src={Okay} />;
        }

    }

    useEffect(() => {
        if (!isComponentVisible || !currentUser) return;
        fetchLastThirtyDayCheckin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, appResumeCounter]);

    useEffect(() => {
        // We need this initial use effect because when this component is loaded after a state variable change, isComponentVisible is not updated
        fetchLastThirtyDayCheckin();
    }, []);

    useEffect(()=> {
        if(!loading && !checkedInToday) {
            processAutoCheckinUrl();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, checkedInToday, appResumeCounter]);


    return(
        <div className="checkin-graph-component journey-card">
            <div className="box-header">
                {checkedInToday && <div className={`box-number ${checkedInToday ? 'complete' : ''}`}>
                    <img src={Check} alt="Check"/>
                </div>}
                <div className="box-title">
                    <div className="title overline">{`1. ${t("Daily Check-In")}`}</div>
                </div>
            </div>
            <div className={`tile ${language?.languageCode}`}>
                <div className={`checkin-graph-container ${!checkedInToday ? 'prompt-visible' : 'graph-visible'} ${props.className ?? ''}`}>
                    {((!checkedInToday && !loading) || !currentUser) && <div className="checkin-prompt-container">
                        <div className={"checkin-prompt header-6-variant"}>{t("How are you feeling today?")}</div>
                        <div className="checkin-buttons">
                            <button className={"checkin-button body-small-variant"} onClick={() => checkin(5)}>
                                <img className={"checkin-image"} alt="Greate" src={Great}/> {t("Great")}
                            </button>
                            <button className={"checkin-button body-small-variant"} onClick={() => checkin(4)}>
                                <img className={"checkin-image"} alt="Good" src={Good}/> {t("Good")}
                            </button>
                            <button className={"checkin-button body-small-variant"} onClick={() => checkin(3)}>
                                <img className={"checkin-image"} alt="Okay" src={Okay}/> {t("Okay")}
                            </button>
                            <button className={"checkin-button body-small-variant"} onClick={() => checkin(2)}>
                                <img className={"checkin-image"} alt="Bad" src={Bad}/> {t("Bad")}
                            </button>
                        </div>
                    </div>}
                    {checkedInToday && !loading && <div className='graph caption'>
                        <ResponsiveContainer>
                            <LineChart
                                data={checkins}
                                margin={{ top: 15, right: 20, left: -20, bottom: 0 }}
                            >
                                <XAxis dataKey="label" tick={{fontSize: 14}} tickMargin={12} axisLine={false} tickLine={false} tickCount={checkins.length-1} interval={checkins.length-2} />
                                <YAxis dataKey="value" domain={[2,5]} axisLine={false} tickLine={false} ticks={[2,3,4,5]} tick={GraphLabel} />
                                <Tooltip content={<GraphTooltip />} />
                                <CartesianGrid horizontal={true} vertical={false} strokeDasharray='5, 5' stroke={Beige} />
                                <Line isAnimationActive={false} stroke={Green} strokeLinecap='round' strokeLinejoin='round' type="monotone" dot={!twoOrMoreDataPointsExists} dataKey="value" strokeWidth={3} />
                                <Line isAnimationActive={false} stroke={Green} fill={Green} strokeLinejoin='round' type="monotone" dot={true} dataKey="previousValue" strokeWidth={3} strokeDasharray="10 3" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>}
                </div>
            </div>
        </div>

    )




}

function GraphTooltip({ active, payload, label }: any) {
    let value;
    if (payload.length) {
        switch(payload[0].payload.value) {
            case 2:
                value = <SVG className='y-axis-label' width={30} height={26} src={Bad} />;
                break;
            case 3:
                value = <SVG className='y-axis-label' width={30} height={26} src={Okay} />;
                break;
            case 4:
                value = <SVG className='y-axis-label' width={30} height={26} src={Good} />;
                break;
            case 5:
                value = <SVG className='y-axis-label' width={30} height={26} src={Great} />;
                break;
        }
    }

    return <div className='chart-label'>{label} {value ? '-' : ''} {value}</div>;
}

