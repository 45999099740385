import * as React from 'react';
import { useContext, useRef, useState, useEffect } from 'react';
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonItemDivider, IonPage, } from "@ionic/react";
import "./ProfilePage.scss";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { CategoryHeader } from "../../components/ContentTiles/CategoryHeader";
import { ContentCarousel } from "../../components/ContentTiles/ContentCarousel";
import AnalyticsService from "../../misc/AnalyticsService";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { ContentItem } from "../../utils/ApiTypes";
import { useLocation, useHistory } from "react-router-dom";
import { TalkToSomeone } from "../../components/TalkToSomeone/TalkToSomeone";
import { ProfileMenuList } from "../../components/Profile/ProfileMenuList";
import { ApiUser } from "../../utils/ApiTypes";
import PencilIcon from "../../assets/images/pencil-gray.svg";
import CircularProgress from '@mui/material/CircularProgress';
import FooterComponent from "../../components/Footer/FooterComponent";
import { PleaseWait } from "../../components/PleaseWait/PleaseWait";
import { InvitationForm } from "../../components/InvitationForm/InvitationForm";
import { useTranslation } from "react-i18next";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { IndustrySelectionMenu } from '../../components/IndustrySelection/IndustrySelectionMenu';
import { Feature } from '../../components/Feature/Feature';


export const ProfilePage: React.FC = () => {
    const { isMobileWidth, currentUser, handleGeneralError, hasCompletedAssessment } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    async function getRecommendations(): Promise<ContentItem[] | undefined> {
        try {
            return await JourneyApiClient.getIonicRecommendations();
        } catch (e) {
            handleGeneralError("Could not fetch recommendations", e);
        }
    }
    const recommendationsQuery = useQuery<Promise<ContentItem[] | undefined>, unknown, ContentItem[]>
        (["getIonicRecommendations"], getRecommendations);

    return (
        <IonPage className="profile-page">

            <ResponsiveNavigationMenu title={"Profile"} />

            <IonContent className="profile-page-content">
                <div className="top-container">
                    <div className={"first-card-container"}>
                        <div className={"journey-card"}>
                            {currentUser && <UserInformation user={currentUser} />}
                        </div>
                        <div className={"personal-assessment-button-container"}>
                            <IonButton
                                disabled={hasCompletedAssessment}
                                className={"personal-assessment-button button-medium-variant"}
                                onClick={() => history.push("/assessment")}>
                                {t("Take Your Personal Assessment")}
                            </IonButton>
                        </div>
                    </div>


                    <div className={"journey-card"}>
                        <ProfileMenuList hideProgressBar={true} />
                    </div>
                    <div className={"journey-card three"}>

                        {currentUser && <InvitationForm user={currentUser} isComponentVisible={isComponentVisible} />}
                    </div>
                </div>
                <div className="bottom-container">
                    <div className={"profile-page-talk-someone-container"}>
                        {currentUser &&
                            <Feature feature="has-eap">
                                <TalkToSomeone
                                    user={currentUser}
                                    isMobileWidth={isMobileWidth}
                                    showDescription={true}
                                    showDescriptionInPopover={false}
                                    isCondensed={false}
                                    subtitle={t("All support is free and confidential.")}
                                    isComponentVisible={isComponentVisible} />
                            </Feature>
                        }
                    </div>

                    {!isMobileWidth && currentUser &&
                        <Feature feature="has-eap">
                            <div className="profile-page-line-divider-container"><IonItemDivider className="profile-page-line-divider" /></div>
                        </Feature>
                    }
                    {currentUser?.company.type !== "trial" &&
                        <>
                            <div className={"recommended-carrousel"}>

                                <CategoryHeader
                                    name={'Recommended'}
                                    description={null}
                                    onClick={() => { }} />
                                {recommendationsQuery.status === "success" &&
                                    <div className={"industry-targeting-row-wrapper"}>
                                        <ContentCarousel onSaveChange={() => { }} onClick={() => {
                                            AnalyticsService.trackUserAction("pp_recommended_video", location.pathname);
                                        }} items={recommendationsQuery.data} />
                                    </div>
                                }
                            </div>
                            <div className="profile-page-line-divider-container"><IonItemDivider className="profile-page-line-divider" /></div>
                        </>
                    }
                    
                </div>
                <FooterComponent />
            </IonContent>
        </IonPage>
    )
};


interface UserInformationProps {
    user: ApiUser;
}

export function UserInformation(props: UserInformationProps) {

    const [isEditNameFormVisible, setIsEditNameFormVisible] = useState<boolean>(false);
    const [isEditPreferredEmailFormVisible, setIsEditPreferredEmailFormVisible] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [preferredEmail, setPreferredEmail] = useState<string | null>(props?.user?.preferredEmail || "");
    const [isNameChangeLoading, setIsNameChangeLoading] = useState<boolean>(false);
    const [isImageChangeLoading, setIsImageChangeLoading] = useState<boolean>(false);
    const [isPreferredEmailChangeLoading, setIsPreferredEmailChangeLoading] = useState<boolean>(false);
    const [preferredEmailCanBeSaved, setPreferredEmailCanBeSaved] = useState<boolean>(false);
    const { handleGeneralError, handleUserError, isMobileWidth } = useContext(ApplicationContext);
    const { t } = useTranslation();
    const fileInput = useRef<any>(null);
    const queryClient = useQueryClient();
    const location = useLocation();

    const nameMutation = useMutation({
        mutationFn: ({ firstName, lastName }: { firstName: string, lastName: string }) => {
            setIsNameChangeLoading(true);
            return JourneyApiClient.saveUserName(firstName, lastName);
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getCurrentUser'] })
            setIsNameChangeLoading(false);
            setIsEditNameFormVisible(false);
            trackNameChange();
        }
    })

    async function handleNameChange() {
        if (!firstName || firstName === "" || !lastName || lastName === "") return;
        try {
            nameMutation.mutate({ firstName, lastName });
        } catch (e) {
            handleGeneralError("Could not update first and last name", e);
        }
    }

    const profileImageMutation = useMutation({
        mutationFn: ({ form }: { form: FormData }) => {
            setIsImageChangeLoading(true);
            return JourneyApiClient.saveUserProfileImage(form);
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getSilentlyCurrentUser'] })
            setIsImageChangeLoading(false);
            trackProfileImageChange();
        }
    })

    async function handleImageUpload(event: any) {
        const form = new FormData();
        const file = event.target.files[0];
        const allowedMimeTypes = ['image/jpeg', 'image/png'];
        if (allowedMimeTypes.includes(file.type)) {
            form.append('profile-image', event.target.files[0]);
            try {
                profileImageMutation.mutate({ form });
            } catch (e) {
                handleGeneralError("Could not update profile image", e);
            }
        } else {
            handleUserError("Image format invalid", "Valid image formats are PNG and JPEG");
        }
    }

    const preferredEmailMutation = useMutation({
        mutationFn: ({ preferredEmail }: { preferredEmail: string }) => {
            setIsPreferredEmailChangeLoading(true);
            return JourneyApiClient.savePreferredEmail(preferredEmail);
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getCurrentUser'] })
            setIsPreferredEmailChangeLoading(false);
            setIsEditPreferredEmailFormVisible(false);
            trackPreferredEmailChange();
        }
    });

    useEffect(() => {
        const preferredEmailHasChanged = preferredEmail?.toLowerCase().trim() !== props.user.preferredEmail?.toLowerCase().trim();
        const isValidPreferredEmail = isValidEmail(preferredEmail || "") || preferredEmail?.trim() === "";

        setPreferredEmailCanBeSaved(
            !isPreferredEmailChangeLoading &&
            preferredEmailHasChanged &&
            isValidPreferredEmail
        );
    }, [preferredEmail, isPreferredEmailChangeLoading, props.user.preferredEmail]);

    async function handlePreferredEmailChange() {
        try {
            preferredEmailMutation.mutate({
                'preferredEmail': preferredEmail?.trim() || "",
            });
        } catch (e) {
            handleGeneralError("Could not update preferred email", e);
        }
    }
    async function trackNameChange() {
        await AnalyticsService.trackUserAction("profile_page_name_change", location.pathname);
    }

    async function trackPreferredEmailChange() {
        await AnalyticsService.trackUserAction("profile_page_preferred_email_change", location.pathname);
    }

    async function trackProfileImageChange() {
        await AnalyticsService.trackUserAction("profile_page_image_change", location.pathname);
    }

    function isValidEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(email).toLowerCase())) {
            return true;
        }

        return false;
    }


    return (
        <div className={"user-information-component"}>
            <div className={"user-image-container"}>
                {isImageChangeLoading ? <PleaseWait /> :
                    <img
                        className={"user-image"}
                        src={props.user.profileImage}
                        alt="Profile"
                        onClick={fileInput.current ? () => fileInput.current.click() : () => { }} />
                }
                <form action="/profile-image" method="post" encType="multipart/form-data">
                    <input className={"input"} type="file" name={"profile-image"} ref={fileInput} onChange={handleImageUpload} />
                </form>
            </div>
            {props.user.company?.logoUrl && <img className={"company-image"} src={props.user.company?.logoUrl} alt={"company-logo"} />}

            <label className='profile-element-label overline'>{t('Name')}</label>
            <div className={"name-and-button-container header-5"}>
                {isEditNameFormVisible ?
                    <div className={"form-container"}>
                        <IonItem className={"form-item"}>
                            <IonInput
                                className={"input body-small"}
                                placeholder={`${t("First Name*") ?? "First Name"}*`}
                                value={firstName}
                                onIonChange={(e) => setFirstName(e.detail.value ?? null)}
                            />
                        </IonItem>
                        <IonItem className={"form-item"}>
                            <IonInput
                                className={"input body-small"}
                                placeholder={`${t("Last Name*") ?? "Last Name"}*`}
                                value={lastName}
                                onIonChange={(e) => setLastName(e.detail.value ?? null)} />
                        </IonItem>
                        <IonButton
                            className={"submit-name-button button-medium-variant"}
                            onClick={handleNameChange}
                            disabled={!firstName || firstName === "" || !lastName || lastName === "" || isNameChangeLoading}>
                            {isNameChangeLoading ? <CircularProgress size={30} /> : t('submit')}
                        </IonButton>
                    </div>

                    :
                    <>{`${firstName ? firstName : props.user.firstName} ${lastName ? lastName : props.user.lastName}`}
                        <IonButton className={"edit-button"} onClick={() => setIsEditNameFormVisible(true)}>
                            <IonIcon icon={PencilIcon} />
                        </IonButton>
                    </>
                }
            </div>
            <div className="card-line-divider-container first"><IonItemDivider className="profile-page-line-divider" /></div>
            <label className='profile-element-label overline'>{t('email')}</label>
            <div className={`user-email ${isMobileWidth ? "body-medium" : "body-large"}`}>
                {props.user.email}
            </div>

            <div className="card-line-divider-container"><IonItemDivider className="profile-page-line-divider" /></div>
            <label className='profile-element-label overline'>{t('Preferred Email')}</label>
            <div className={`preferred-email-and-button-container ${isMobileWidth ? "body-medium" : "body-large"}`}>
                {isEditPreferredEmailFormVisible ?
                    <div className={"form-container"}>
                        <IonItem className={"form-item"}>
                            <IonInput
                                className={"input body-small"}
                                placeholder={`${t("Preferred Email")}`}
                                value={preferredEmail}
                                type="email"
                                onIonChange={(e) => {
                                    setPreferredEmail(e.detail.value ?? "");
                                }}
                            />
                        </IonItem>
                        <IonButton
                            className={"submit-preferred-email-button button-medium-variant"}
                            onClick={handlePreferredEmailChange}
                            disabled={!preferredEmailCanBeSaved}>
                            {isPreferredEmailChangeLoading ? <CircularProgress size={30} /> : t('submit')}
                        </IonButton>
                    </div>

                    :
                    <>{`${preferredEmail || "\u200e"}`}
                        <IonButton className={"edit-button"} onClick={() => setIsEditPreferredEmailFormVisible(true)}>
                            <IonIcon icon={PencilIcon} />
                        </IonButton>
                    </>
                }
            </div>

            <div className="card-line-divider-container"><IonItemDivider className="profile-page-line-divider" /></div>

            {(props.user.userRoles.indexOf('Sales') > -1) &&
                <>
                    <div className="card-line-divider-container"><IonItemDivider className="profile-page-line-divider" /></div>
                    <label className='profile-element-label overline'>{t('Industry')}</label>
                    <div className="user-language-selector language-or-country-component">
                        <IndustrySelectionMenu />

                    </div>
                </>
            }
        </div >
    )
}
