import { ContentItem } from "../../utils/ApiTypes";
import { SyntheticEvent, useContext } from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../../misc/ApplicationContext";
import "./Tile.scss";
import {BACKGROUND_TILE_COLOR} from "../../utils/utils";

export type GenericTileProps = {
    item: ContentItem,
    className?: string
};

function preventDefault(e: SyntheticEvent) {
    e.preventDefault();
}

function noop() {}

export function GenericTile(props: GenericTileProps) {

    const { currentUser } = useContext(ApplicationContext);

    return(
        <div className={"tile-component"}>
            <Link
                onClick={currentUser ? noop : preventDefault} to={`${props.item.path}`}
                className={`tile-container ${props.className ?? ''} ${BACKGROUND_TILE_COLOR[Math.round(Math.random()*10) % 4]} `}>
                <div className={"title-container"}>
                    {(props.item.title ?? true) && <div className={"title subtitle2-variant"}>{props.item.title}</div>}
                    <div className={"author-container"}>
                    </div>
                </div>
            </Link>
            <div className={"title-and-description-container"}>
                {props.item.title &&<div className={"caption"}>{props.item.title}</div>}
            </div>
        </div>
    )
}