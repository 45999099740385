import "./RegisterPage.scss";
import React from "react";
import * as Sentry from "@sentry/react";
import { useState, useContext } from "react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { IonPage, IonContent, IonButton, IonHeader } from "@ionic/react";
import DOMPurify from 'dompurify';
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { useParams } from "react-router-dom";
import { ApiCompany, ApiCountry, FeatureConfig } from "../../utils/ApiTypes";
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import { PleaseWait } from "../../components/PleaseWait/PleaseWait";
import Logo from "../../assets/images/logo-journey.svg";
import { SignUpForm } from "../../components/SignUpForm/SignUpForm";
import { SignUpFormError } from "../../components/SignUpFormError/SignUpFormError";
import { VideoTile } from "../../components/ContentTiles/VideoTile";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';
import { MiniLanguageMenuHeader } from "../../components/MiniLanguageMenu/MiniLanguageMenuHeader";
import { MiniLanguagesMenu } from "../../components/MiniLanguageMenu/MiniLanguagesMenu";

export const RegisterPage: React.FC = () => {

    const { isMiniLanguageMenuOpen } = useContext(ApplicationContext);
    const [company, setCompany] = useState<ApiCompany | null>(null);
    const [userHasAccessToEmail, setUserHasAccessToEmail] = useState<boolean | null>(null);
    const isComponentVisible = useIsComponentVisible();
    const { companyCode } = useParams<{ companyCode: string }>();
    const DEFAULT_PLAYLIST_ID = 996103;
    const DEFAULT_IMAGE_URL = '/registration-pages/SelfRegistration+-+Journey.png';
    const { t } = useTranslation();
    const [features, setFeatures] = useState<FeatureConfig>({ features: {} });
    const [registerCompanyCountries, setRegisterCompanyCountries] = useState<boolean>(false);

    async function fetchCompany() {
        setRegisterCompanyCountries(false);
        if (!companyCode) {
            Sentry.captureEvent({ message: "Invalid Url for Self-Registration Page", level: 'info' });
        }
        const company = await JourneyApiClient.getCompany(companyCode);
        setCompany(company);

        if (company) {
            const companyFeatures = await JourneyApiClient.getCompanyFeatureConfiguration(company.id);
            setFeatures(companyFeatures);

            // If the company uses SSO display the usual signup form, user has access to company email
            if (company.isSsoLogin) {
                setUserHasAccessToEmail(true);
            }

            if (company.code) {
                JourneyApiClient.getCompanyCountries(company.code)
                    .then((countries: Array<ApiCountry>) => {
                        setRegisterCompanyCountries(countries.length > 0);
                    });
            }
        }

        return company;
    }

    async function fetchPlaylist(company: ApiCompany) {
        const playlist = await JourneyApiClient.getRegistrationPageVideos(company.registrationPagePlaylistId ?? DEFAULT_PLAYLIST_ID);
        return playlist;
    }

    const companyResult = useQuery(["fetchCompany", isComponentVisible], fetchCompany);
    if (companyResult.status === "error") {
        Sentry.captureException(companyResult.error);
    }

    const playlistResult = useQuery(["fetchPlaylist", company], () => fetchPlaylist(company!), {
        enabled: !!company
    });
    if (playlistResult.status === "error") {
        Sentry.captureException(playlistResult.error);
    }

    let rawMessage: string = t("Welcome to Journey, a benefit that comes at no cost to you and your loved ones. Experience the benefits of proactive mental health content, therapy services, and personalized resources designed to support your well-being. Embrace this opportunity to prioritize your mental health and embark on a transformative journey of self-care and growth. Simply enter your email below to start!");

    if (companyResult.status === "success" && company?.registrationPageMessage) {
        rawMessage = t(company.registrationPageMessage);
    }
    else if (companyResult.status === "success" && company?.isFriendsAndFamily) {
        rawMessage = t("Congratulations on receiving a special invitation to Journey, a gift that comes at no cost to you and your loved ones. Experience the benefits of proactive mental health content, therapy services, and personalized resources designed to support your well-being. Embrace this opportunity to prioritize your mental health and embark on a transformative journey of self-care and growth. Simply enter your email below to start!");
    }

    const submitAccessToEmailAnswer = async (answer: boolean) => {
        setUserHasAccessToEmail(answer);
    }

    const displayMessage = DOMPurify.sanitize(rawMessage);

    return (
        <IonPage className="register-page-component">

            <IonContent className='register-page-content'>
                <div className='register-page'>
                    {company?.countries && company?.countries.length >= 1 &&
                        <IonHeader>
                            {isMiniLanguageMenuOpen && <MiniLanguagesMenu companyId={company?.id} sendToHome={false} rightMargin={1} />}
                            {registerCompanyCountries && <div className='language-header'>
                                <MiniLanguageMenuHeader />
                            </div>
                            }
                        </IonHeader>
                    }
                    <div className='header'>
                        <img src={company?.appLogoUrl ?? Logo} className="logo-image" alt="Logo" />
                    </div>
                    <div className='body'>
                        <div className="two-panel-container">
                            <div className="left-panel">
                                {
                                    features.features['has-eap'] ?
                                        <h1 className="welcome">{t("Welcome to Journey Proactive EAP")}</h1>
                                        :
                                        <h1 className="welcome">{t("Welcome to Journey!")}</h1>
                                }
                                <h3 className="message" dangerouslySetInnerHTML={{ __html: displayMessage }}></h3>
                                {
                                    companyResult.status === "success" && company &&
                                    <img
                                        src={`https://journeylive.imgix.net/${process.env.NODE_ENV}/${company?.registrationPageImageUrl ?? DEFAULT_IMAGE_URL}`}
                                        alt="Registration Page"
                                    />
                                }
                            </div>
                            <div className="right-panel">
                                {companyResult.status === "loading" && <PleaseWait />}
                                {companyResult.status === "error" && <SignUpFormError />}
                                {
                                    companyResult.status === "success" && userHasAccessToEmail &&
                                    <div className="sign-up-form paper-container">
                                        <SignUpForm emailType="company" company={companyResult.data!} title={t("Register for Free")} realmDiscovery={true} />
                                    </div>
                                }
                                {
                                    companyResult.status === "success" && userHasAccessToEmail === false &&
                                    <div className="sign-up-form paper-container">
                                        <SignUpForm emailType="preferred" company={companyResult.data!} title={t("Register for Free")} realmDiscovery={false} />
                                    </div>
                                }
                                {
                                    companyResult.status === "success" && userHasAccessToEmail === null &&
                                    <div className="sign-up-form paper-container">
                                        <h3 className="message">{t("Do you have access to your company email address?")}</h3>
                                        <div className="access-email-button-container">
                                            <IonButton color='light' type='submit' className='paper-submit-button-variation' onClick={() => submitAccessToEmailAnswer(false)} >
                                                {t('No')}
                                            </IonButton>
                                            <IonButton type='submit' className='paper-submit-button-variation' onClick={() => submitAccessToEmailAnswer(true)} >
                                                {t('Yes')}
                                            </IonButton>
                                        </div>
                                    </div>
                                }
                                <h2 className="sneak-peak">{t("Sneak Peek of Journey")}</h2>
                                {
                                    playlistResult.status === "success" &&
                                    <div className="videos-container">
                                        {
                                            playlistResult.data.videos.map((item, i) => {
                                                switch (item.type) {
                                                    case 'video':
                                                        return (
                                                            <div key={i} className='content-tile'>
                                                                <VideoTile showTitle={false} showDescription={false} key={i} video={item} />
                                                            </div>
                                                        );
                                                    default:
                                                        return <div key={i}></div>
                                                }
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}
