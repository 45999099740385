import { VideoResponse } from "../../utils/ApiTypes";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import React, { EventHandler, SyntheticEvent, useContext, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import AnalyticsService from "../../misc/AnalyticsService";
import { ApplicationContext } from "../../misc/ApplicationContext";
import "./Tile.scss";
import moment from "moment-timezone";
import {VideoStyle} from "./ContentStyles/Video/VideoStyle";
import {AudioStyle} from "./ContentStyles/Audio/AudioStyle";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ClampLines from "../ClampLines/ClampLines";


export type VideoTileProps = {
    video: VideoResponse;
    index?: number;
    showDescription?: boolean;
    showTitle?: boolean;
    className?: string;
    onSaveChange?: (saved: boolean) => void;
    onClick?: EventHandler<any>;
    playlistId?: number;
};

function preventDefault(e: SyntheticEvent) {
    // e.preventDefault();
}

function noop() {}

export function VideoTile(props: VideoTileProps) {
    const { video } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(video.isSaved);
    const { currentUser, handleGeneralError, industryCssClass } = useContext(ApplicationContext);
    const link = currentUser ? video.path : ( video.isPublic ? `/video/public/${video.id}` : '#');
    const location = useLocation();
    const queryClient = useQueryClient();

    let durationMinutes = '';
    let durationSeconds = '';
    if (video.duration) {
        durationMinutes = Math.floor(video.duration / 60).toString();
        durationSeconds = (video.duration % 60).toString().padStart(2, '0');
    }

    let progress = 0;
    if (video.position && video.duration) {
        progress = 100 / video.duration * video.position;
    }

    function trackSaveVideo() {
        AnalyticsService.trackUserAction("save_video", location.pathname, {
            video_id: video.id });
    }

    function trackRemoveSaveVideo() {
        AnalyticsService.trackUserAction("remove_save_video", location.pathname, {
            video_id: video.id })
    }

    const addToListMutation = useMutation({
        mutationFn: () => {
            setLoading(true);
            return JourneyApiClient.saveVideo(video.id);
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getSavedVideos'] })
            setIsSaved(true);
            if (props.onSaveChange) props.onSaveChange(true);
            setLoading(false);
            trackSaveVideo();
        }
    })

    async function addToList(e: SyntheticEvent) {
        e.preventDefault()
        if (loading) return;
        try{
            addToListMutation.mutate();
        } catch (e) {
            handleGeneralError("Could not update add video to list", e);
        }
    }

    const removeFromListMutation = useMutation({
        mutationFn: () => {
            setLoading(true);
            return JourneyApiClient.removeSavedVideo(video.id);
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getSavedVideos'] })
            setIsSaved(false);
            if (props.onSaveChange) props.onSaveChange(false);
            setLoading(false);
            trackRemoveSaveVideo();
        }
    })

    async function removeFromList(e: SyntheticEvent) {
        e.preventDefault();
        if (loading) return;
        try{
            removeFromListMutation.mutate();
        } catch (e) {
            handleGeneralError("Could not update remove video from list", e);
        }
    }

    function formatDate(date: Date) {
        return moment(date).tz(moment.tz.guess()).format("dddd MMM Do - h:mm A");
    }

    function renderTile(theme: string | null) {
        switch (theme){
            case null:
                return
            case "video":
                return(
                    <VideoStyle industryClass={industryCssClass} durationMinutes={durationMinutes} durationSeconds={durationSeconds} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} progress={progress} video={video}/>
                )
            case "audio":
                return(
                    <AudioStyle durationMinutes={durationMinutes} durationSeconds={durationSeconds} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} progress={progress} content={video} />
                )

        }
    }

    return(
        <div className={"tile-component"}>
            <Link
                onClick={currentUser ? props.onClick ?? noop : preventDefault}
                to={link}
                className={`tile-container ${props.className ?? ''}`}>
                {renderTile(video.thumbnailStyle)}
            </Link>
            {(video.liveRecordingDate || props.showTitle || props.showDescription) && <div className={"title-and-description-container"}>
                {video.liveRecordingDate && <div className={"caption"}>{formatDate(video.liveRecordingDate)}</div>}
                {(props.showTitle ?? true) &&<div className={"out-of-tile-title caption"}>{video.title}</div>}
                {(props.showDescription || video.liveRecordingDate) && <div className={"caption"}>
                    <ClampLines
                        showButton={false}
                        text={video.description ?? ''}
                        lines={2}
                        ellipsis="..."
                        className={`clamp-video-description caption`}
                    />
                    </div>}
            </div>}
        </div>
    )
}
